const introToJSLecture = {
    id: 1,
    title: `Intro to JS`,
    description: `A brief into lecture to JS`,
    image: `/images/projectImages/JavaScript-logo.png`,
    date: `1/14/2021`,
    role: `Creator and Presenter`,
    slidesLink: ``,
    youtubeLink: ``,
}

const allLectureData = [introToJSLecture]

export default allLectureData
